<script src="../../router/routers.js"></script>
<template>
  <div class="profile-modal" :class="{ active: show }" @click="closeModal()">
    <transition name="slide-down">
      <div
        v-if="show"
        class="profile-modal__dialog"
        :class="propsModal ? 'profile-modal__dialog--short' : ''"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "ModalProflie",
  props: {
    propsModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      // document.querySelector("body").style.cssText =
      //   "height: unset; overflow-y: unset";
      this.$emit("showOption");
    },
    openModal() {
      this.show = true;
      // document.querySelector("body").style.cssText =
      //   "height: 100vh; overflow-y: hidden";
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  visibility: hidden;

  &.active {
    visibility: visible;
  }

  &__dialog {
    position: fixed;
    bottom: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 20px 19px;
    width: 100%;
    background-color: #fff;
    height: 110px;
    &--short {
      height: 50px;
    }
  }
}

.slide-down {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.5s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 992px) {
  .profile-modal {
    &__dialog {
      position: fixed;
      bottom: 111px;
      border-radius: 20px;
      padding: 25px 20px;
      width: 100%;
      max-width: 467px;
      background-color: #fff;
      height: 140px;
      &--short {
        height: 60px;
      }
    }
  }
}
</style>
